import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import IconPuzzleHouse from '../../Component/Icon/IconPuzzleHouse';
import SectionLink from '../../Component/SectionLink/SectionLink';
import { makeRoutePath } from '../../store/route/route.api';
import { bienMocked } from '../MesBiensPage/BienMocked';
import useShowBienStore from '../ShowBienLayout/useShowBienStore';

import './DetailBienPage.scss';

export default function DetailBienPage() {
  const { bien } = useShowBienStore();
  const { bienId } = useParams();

  // const bien = bienMocked;

  const { formatMessage } = useIntl();

  const { roomList } = useMemo(() => {
    const roomNames = [];
    const roomList = [];
    const roomCounts = {};

    if (bien?.pieces !== null) {
      bien?.pieces?.forEach((room) => {
        const name = room?.nom;
        const count = roomNames?.filter((n) => n.startsWith(name)).length;
        const number = count > 0 ? count + 1 : '';

        roomCounts[name] = number;

        roomNames?.push(number ? `${name} ${number}` : name);
        roomList?.push({
          key: number ? `${name} ${number} ${room?.id}` : `${name} ${room?.id}`,
          id: room?.id,
          title: number ? `${name} ${number}` : name,
          url: makeRoutePath('bien.show.details.roomId', { bienId, roomId: room?.id }),
        });
      });
    }

    return { roomList };
  }, [bienId, bien?.pieces]);

  const roomListTitle = useMemo(() => (roomList?.map((room) => (
    <SectionLink key={room.id} to={room?.url} label={room?.title} />
  ))), [roomList]);

  return (
    <>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        to={makeRoutePath('bien.show', { bienId })}
        transparent
      />

      {roomListTitle?.length >= 1
        ? (
          <FlexRow
            className="container_SectionLink"
            direction="column"
            alignItems="center"
            justifyContent="start"
          >
            <SectionLink key="general" to={makeRoutePath('bien.show.details.roomId', { bienId, roomId: 'general' })} label="Généralités" />
            {roomListTitle}
          </FlexRow>
        )
        : (
          <FlexRow className="container_SectionLink" alignItems="center" justifyContent="center">
            <IconPuzzleHouse
              className="img-w5xl img-h5xl"
            />
            <p className="textInfo">{formatMessage({ id: 'roomList.empty.info' })}</p>
          </FlexRow>
        ) }

    </>
  );
}
